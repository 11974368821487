import { useEffect, useState } from 'react'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export const useHubConnection = (
  url,
  logLevel = LogLevel.Debug,
  callback = null
) => {
  const [connection, setConnection] = useState(null)
  const [connectionState, setConnectionState] = useState({
    status: 'CONNECTING',
    error: null,
  })
  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(url)
      .configureLogging(logLevel)
      .build()
    setConnection(newConnection)
    newConnection.onclose((error) => {
      if (process.env.REACT_APP_STAGE !== 'production' && error)
        console.warn(error)
      if (error) {
        setConnectionState({
          status: 'ERROR',
          error,
        })
      } else {
        setConnectionState({
          status: 'DISCONNECTED',
          error: null,
        })
      }
    })
    newConnection.onreconnecting((error) => {
      setConnectionState({
        status: 'RECONNECTING',
        error,
      })
    })
    newConnection.onreconnected(() => {
      setConnectionState({
        status: 'CONNECTED',
        error: null,
      })
    })
    newConnection
      .start()
      .then(() => {
        if (typeof callback === 'function') callback(newConnection)
        setConnectionState({
          status: 'CONNECTED',
          error: null,
        })
      })
      .catch((error) => {
        setConnectionState({
          status: 'ERROR',
          error,
        })
      })
    return () => {
      return newConnection.stop()
    }
  }, [url, logLevel, callback])
  return {
    connection,
    connectionState,
  }
}

export const useMessageListener = (connection, method, listener) => {
  useEffect(() => {
    // Wait until the connection is ready
    if (!connection) return
    connection.on(method, listener)
    return () => {
      connection.off(method, listener)
    }
  }, [connection, method, listener])
}
