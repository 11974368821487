import React from 'react'
import gridClasses from '../../styles/grid.module.css'
import classes from './mobileDetails.module.css'
import { Header } from '../../components/Header/Header'
import classNames from 'classnames'
import topImage from '../../assets/images/top-image.jpg'
import topBgImage from '../../assets/images/white-leo.png'
import blueBgImage from '../../assets/images/blue-leo.png'
import curvedBgImage from '../../assets/images/curved-bg.png'
import contactPhoneImage from '../../assets/images/contact.png'
import newsPhoneImage from '../../assets/images/news.png'
import dataPhoneImage from '../../assets/images/data.png'
import testPhoneImage from '../../assets/images/test.png'
import { ReactComponent as Dots } from '../../assets/icons/dots.svg'
import { ReactComponent as CircleDots } from '../../assets/icons/circle-dots.svg'
import { ReactComponent as AppStore } from '../../assets/images/app-store.svg'
import { ReactComponent as PlayStore } from '../../assets/images/play-store.svg'
import Footer from '../../components/Footer/Footer'
import { GetTheAppCard } from '../../components/Cards/GetTheAppCard/GetTheAppCard'

const MobileDetails = () => {
  return (
    <>
      <Header className={classes.header} />
      <section>
        <img
          src={topBgImage}
          alt="Background"
          className={classes.sectionBgImage}
        />
        <div className={classNames(classes.top, gridClasses.container)}>
          <h1 className={classes.topHeader}>
            A community driven approach to identify close contacts of users for
            effective contact tracing
          </h1>
          <h3 className={classes.topSubtext}>
            Support the work of contact tracers and healthcare workers by
            combating the spread of COVID-19 together.
          </h3>
          <div className={classes.topAppLinks}>
            <a
              href="https://play.google.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download the Android app"
            >
              <PlayStore />
            </a>
            <a
              href="https://www.apple.com/ios/app-store/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download the iOS app"
            >
              <AppStore />
            </a>
          </div>
          <div className={classes.topImageWrapper}>
            <Dots className={classes.topImageDotsBottom} />
            <Dots className={classes.topImageDotsRight} />
            <img
              src={topImage}
              alt="Placeholder"
              className={classes.topImage}
            />
          </div>
        </div>
      </section>
      <section className={classes.how}>
        <div className={classes.howSectionWrapper}>
          <img
            src={blueBgImage}
            alt="Background"
            className={classes.sectionBgImage}
          />
          <Dots
            className={classes.howSectionDots}
            style={{ color: '#EEC200' }}
          />
          <div
            className={classNames(classes.howSection, gridClasses.container)}
          >
            <h1 className={classes.howHeader}>
              How GloEpid Mobile App helps you stay safe.
            </h1>
            <div className={classes.howSectionGrid}>
              <img
                src={contactPhoneImage}
                alt="App Preview"
                className={classes.howSectionGridImage}
              />
              <div className={classes.gridText}>
                <p className={classes.gridTextAppName}>GLOEPID APP</p>
                <h2 className={classes.gridTextHeader}>
                  Faster Contact Tracing
                </h2>
                <h4 className={classes.gridTextSubtext}>
                  Using Bluetooth and GPS, other devices with the app installed
                  within a range are identified and stored as an activity log
                  with timestamps and location points. With your permission,
                  this Information is made available to the relevant authorities
                  if it is necessary.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.howSectionWrapper}>
          <img
            src={curvedBgImage}
            alt="Background"
            className={classNames(
              classes.sectionBgImage,
              classes.sectionBgImageBottom
            )}
          />
          <div
            className={classNames(classes.howSection, gridClasses.container)}
          >
            <div className={classes.howSectionGrid}>
              <div className={classes.gridText}>
                <p className={classes.gridTextAppName}>GLOEPID APP</p>
                <h2 className={classes.gridTextHeader}>Self Assessment Test</h2>
                <h4 className={classes.gridTextSubtext}>
                  Take assessment tests in-app, know your Risk factor levels,
                  follow the suggestions and have your report sent directly to
                  the relevant authorities for follow-up activities.
                </h4>
              </div>
              <img
                src={testPhoneImage}
                alt="App Preview"
                className={classes.howSectionGridImage}
              />
            </div>
          </div>
        </div>
        <div className={classes.howSectionWrapper}>
          <img
            src={blueBgImage}
            alt="Background"
            className={classes.sectionBgImage}
          />
          <CircleDots
            className={classNames(
              classes.howSectionDots,
              classes.howSectionCircleDot
            )}
            style={{ color: '#108043' }}
          />
          <div
            className={classNames(classes.howSection, gridClasses.container)}
          >
            <div className={classes.howSectionGrid}>
              <img
                src={dataPhoneImage}
                alt="App Preview"
                className={classes.howSectionGridImage}
              />
              <div className={classes.gridText}>
                <p className={classes.gridTextAppName}>GLOEPID APP</p>
                <h2 className={classes.gridTextHeader}>Data Privacy</h2>
                <h4 className={classes.gridTextSubtext}>
                  All data is encrypted and stored on your device and cannot be
                  accessed unless you are identified as a close contact or
                  confirmed case of COVID-19 and have given consent.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.howSectionWrapper}>
          <img
            src={curvedBgImage}
            alt="Background"
            className={classNames(
              classes.sectionBgImage,
              classes.sectionBgImageBottom
            )}
          />
          <div
            className={classNames(classes.howSection, gridClasses.container)}
          >
            <div className={classes.howSectionGrid}>
              <div className={classes.gridText}>
                <p className={classes.gridTextAppName}>GLOEPID APP</p>
                <h2 className={classes.gridTextHeader}>
                  COVID-19 News Updates
                </h2>
                <h4 className={classes.gridTextSubtext}>
                  Read lastest information andupdates about COVID-19 directly
                  from credible sources.
                </h4>
              </div>
              <img
                src={newsPhoneImage}
                alt="App Preview"
                className={classes.howSectionGridImage}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={classes.getTheApp}>
        <GetTheAppCard />
      </section>
      <Footer />
    </>
  )
}

export default MobileDetails
