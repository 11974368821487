import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import LandingPage from './routes/Landing/Landing'
import MobileDetails from './routes/MobileDetails/MobileDetails'
import Contact from './routes/Contact/Contact'
import AboutUs from './routes/AboutUs/AboutUs'
import BotOnly from './routes/BotOnly/BotOnly'
import Products from './routes/Products/products'
import Services from './routes/Services/services'

function App() {
  return (
    <Router>
      {/* <Switch>
        <RouteWithBot path="/" exact component={LandingPage} />
        {process.env.REACT_APP_STAGE !== 'production' && (
          <RouteWithBot path="/old-home" component={HomePage} />
        )}
        <RouteWithBot path="/mobile-details" component={MobileDetails} />
        <RouteWithBot path={'/contact'} component={Contact} />
        <RouteWithBot path="/about" component={AboutUs} />
        <Route path="/bot" component={BotOnly} />
      </Switch> */}
      <Route path="/" exact component={LandingPage} />
      <Route path={'/contact'} component={Contact} />
      <Route path="/about" component={AboutUs} />
      <Route path="/bot" component={BotOnly} />
      <Route path="/mobile-details" component={MobileDetails} />
      <Route path="/products" component={Products} />
      <Route path="/services" component={Services} />
    </Router>
  )
}

export default App
