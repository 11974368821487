import React from 'react'
import classNames from 'classnames'
import classes from './ChatWindow.module.css'

export const ChipInput = ({
  label,
  selected,
  onClick,
  className,
  ...props
}) => (
  <label
    className={classNames(classes.chip, selected && classes.chipSelected)}
    htmlFor={`select-${label}`}
    onClick={onClick}
  >
    <input type="checkbox" name={`select-${label}`} {...props} />
    <span className={classes.chipText} checked={selected}>
      {label}
    </span>
  </label>
)

export const ChipButton = ({
  type = 'button',
  onClick,
  className,
  children,
  ...props
}) => (
  <button
    type={type}
    className={classNames(classes.chip, classes.chipButton)}
    onClick={onClick}
    {...props}
  >
    <span className={classes.chipText}>{children}</span>
  </button>
)
