import React, { forwardRef } from 'react'
import classNames from 'classnames'
import classes from './ChatWindow.module.css'
import botAvatar from '../../assets/images/bot-icon.svg'
import moment from 'moment'
import { ChipButton } from './Chips'
import ReactMarkdown from 'react-markdown'
import { ReactComponent as HighRiskIcon } from '../../assets/icons/high-risk.svg'
import { ReactComponent as MediumRiskIcon } from '../../assets/icons/medium-risk.svg'
import { ReactComponent as LowRiskIcon } from '../../assets/icons/low-risk.svg'

const deliveryStatuses = {
  sent: 'Sent',
  delivered: 'Delivered',
  read: 'Seen',
  error: 'Error',
}

export const _getDeliverStatusText = (deliveryStatus) =>
  deliveryStatuses[deliveryStatus]

const _differenceInMinutes = (dateA, dateB) =>
  Math.abs(moment(dateA).diff(dateB, 'minutes', true))

const _formatTime = (date) => moment(date).fromNow()

const allowedNodeTypes = ['root', 'text', 'paragraph', 'strong', 'emphasis']

const MessageRow = ({
  sent = false,
  children,
  showMessageMeta = true,
  messageMeta: { date, deliveryStatus } = {},
  showAvatar = true,
}) => (
  <div
    className={classNames(
      classes.messageRow,
      sent && classes.messageRowSent,
      showMessageMeta && classes.messageRowSpaced,
      !showMessageMeta && classes.messageRowCollapsed
    )}
  >
    {!sent && (
      <div className={classes.messageAvatarWrapper}>
        {showAvatar && (
          <img
            className={classes.messageAvatar}
            alt="Geopid Bot"
            src={botAvatar}
          />
        )}
      </div>
    )}
    <div className={classes.messageContent}>
      {children}
      {showMessageMeta && (
        <div className={classes.messageMeta}>
          <span className={classes.messageMetaItem}>{_formatTime(date)}</span>
          {sent && (
            <span
              className={classNames(
                classes.messageMetaItem,
                deliveryStatus === 'read' && classes.messageMetaItemEmphasized
              )}
            >
              . {_getDeliverStatusText(deliveryStatus)}
            </span>
          )}
        </div>
      )}
    </div>
  </div>
)

const MessageBox = ({ className, children }) => (
  <div className={classNames(classes.messageBox, className)}>{children}</div>
)

const ChipList = ({ children }) => (
  <div className={classNames(classes.chipList, classes.inlineChipList)}>
    {' '}
    {children}
  </div>
)

const riskLevelRegex = /(low|high|medium)\s+risk/i
const riskLevelClasses = {
  low: classes.lowRisk,
  medium: classes.mediumRisk,
  high: classes.highRisk,
}
const riskLevelIcons = {
  low: LowRiskIcon,
  medium: MediumRiskIcon,
  high: HighRiskIcon,
}
const getRiskLevelClass = (riskLevel) => riskLevelClasses[riskLevel]
const getRiskLevelIcon = (riskLevel) => riskLevelIcons[riskLevel]

export const ChatMessages = forwardRef(
  (
    {
      className,
      messages = [],
      showTypingIndicator = false,
      showConversationEndPrompts = false,
      ...props
    },
    ref
  ) => (
    <div
      className={classNames(classes.chatArea, className)}
      ref={ref}
      {...props}
    >
      {messages.map((message, index) => {
        const nextIndex = index + 1
        const previousIndex = index - 1
        const nextMessage = messages[nextIndex]
        const previousMessage = messages[previousIndex]
        let showMessageMeta = true
        let showAvatar = true
        if (nextMessage) {
          // This checks to ensure the next message originates from
          // the same source as the current message
          // e.g if this message is from the bot and the next message is also
          // from the bot
          if (message.sent === nextMessage.sent) {
            if (_differenceInMinutes(nextMessage.date, message.date) < 1)
              showMessageMeta = false
          }
        }
        if (previousMessage) {
          if (previousMessage.sent === message.sent) {
            if (_differenceInMinutes(message.date, previousMessage.date) < 1)
              showAvatar = false
          }
        }
        const isRiskLevelMessage =
          !message.sent && riskLevelRegex.test(message.message)
        const riskLevel =
          isRiskLevelMessage &&
          riskLevelRegex.exec(message.message)[1].toLowerCase()
        const Icon = isRiskLevelMessage && getRiskLevelIcon(riskLevel)
        return (
          <MessageRow
            sent={message.sent}
            showAvatar={showAvatar}
            showMessageMeta={showMessageMeta}
            messageMeta={{
              date: message.date,
              deliveryStatus: message.deliveryStatus,
            }}
            key={message.id}
          >
            <MessageBox
              className={classNames(
                isRiskLevelMessage && classes.riskLevelBox,
                isRiskLevelMessage && getRiskLevelClass(riskLevel)
              )}
            >
              {isRiskLevelMessage && <Icon className={classes.riskLevelIcon} />}
              <ReactMarkdown
                allowedTypes={allowedNodeTypes}
                source={message.message}
              />
            </MessageBox>
          </MessageRow>
        )
      })}
      {showTypingIndicator && (
        <MessageRow showMessageMeta={false}>
          <MessageBox>
            <span className={classes.typingIndicatorText}>
              GleopidBot is typing...
            </span>
          </MessageBox>
        </MessageRow>
      )}
      {showConversationEndPrompts && (
        <React.Fragment>
          <MessageRow showMessageMeta={false}>
            <MessageBox>
              Want to learn more?{' '}
              <a
                href="https://wa.me/2347087110839?text=Help"
                target="_blank"
                rel="noreferrer noopener"
              >
                Chat with the NCDC bot on WhatsApp
              </a>
            </MessageBox>
          </MessageRow>
          <MessageRow showAvatar={false} showMessageMeta={false}>
            <MessageBox>
              Want others to also take the self assessment test? Share it with
              them!
            </MessageBox>
          </MessageRow>
          <MessageRow showAvatar={false} showMessageMeta={false}>
            <ChipList>
              <ChipButton>Share on Twitter</ChipButton>
              <ChipButton>Share on Facebook</ChipButton>
              <ChipButton>Share on Whatsapp</ChipButton>
              <ChipButton>Copy Link</ChipButton>
            </ChipList>
          </MessageRow>
        </React.Fragment>
      )}
    </div>
  )
)
