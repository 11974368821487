import React from 'react'
import classes from './services.module.css'
import { Header } from '../../components/Header/Header'
import gridClasses from '../../styles/grid.module.css'
import Footer from '../../components/Footer/Footer'
import healthConsult from '../../assets/icons/healthConsult.svg'
import healthSearch from '../../assets/icons/healthSearch.svg'
import healthSystem from '../../assets/icons/healtSystem.svg'
import healthAssess from '../../assets/icons/healthAssess.svg'
import logoTrans from '../../assets/icons/logoServiceTrans.svg'

function Services() {
  const servicesList = [
    {
      icon: healthConsult,
      title: 'Health Technology Consultancy',
    },
    {
      icon: healthAssess,
      title: 'Health Technology Design and Evaluation',
    },
    {
      icon: healthSystem,
      title: 'Health Systems Strengthening',
    },
    {
      icon: healthSearch,
      title: 'Monitoring and Evaluation',
    },
  ]
  return (
    <div>
      <div className={classes.productHero}>
        <Header />
        <div className={gridClasses.container}>
          <h2 className={classes.heroTitle}>Services</h2>
          <div className={classes.serviceSection}>
            <div className={classes.servicesHeroContainer}>
              <div className={classes.servicesHeroWrapper}>
                {servicesList.map((service, index) => (
                  <div className={classes.serviceCardWrap} key={index}>
                    <img
                      src={service.icon}
                      alt=""
                      className={classes.serviceCardIcon}
                    />
                    <div className={classes.serviceCard} key={index}>
                      <h3 className={classes.serviceCardTitle}>
                        {service.title}
                      </h3>
                      <img
                        src={logoTrans}
                        alt=""
                        className={classes.transLogo}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Services
