import React, { useEffect, useCallback, useMemo } from 'react'
import ChatWindow from '../../components/ChatWindow/ChatWindow'
// eslint-disable-next-line
import classes from './BotOnly.module.css'

export default function BotOnly({ location }) {
  const receiveMessage = useCallback(() => {}, [])
  const windowStateChange = useCallback((hidden) => {
    window.top.postMessage(
      {
        type: 'windowStateChange',
        value: hidden,
      },
      '*'
    )
  }, [])
  const params = new URLSearchParams(location.search)
  const partnerId = parseInt(params.get('partnerId'))
  const configuration = useMemo(
    () => ({
      partnerId: isNaN(partnerId) ? null : partnerId,
    }),
    [partnerId]
  )
  useEffect(() => {
    document.body.classList.add('bot-only')
    window.onmessage = receiveMessage
    return () => {
      window.onmessage = null
      document.body.classList.remove('bot-only')
    }
  }, [receiveMessage])
  return (
    <ChatWindow
      onWindowStateChange={windowStateChange}
      configuration={configuration}
    />
  )
}
