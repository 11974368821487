import React, { useState } from 'react'
import { Header } from '../../components/Header/Header'
import classes from './Contact.module.css'
import gridClasses from '../../styles/grid.module.css'
import topBg from '../../assets/images/contact-bg.png'
import classNames from 'classnames'
import { ReactComponent as Email } from '../../assets/icons/email-icon.svg'
import { ReactComponent as Location } from '../../assets/icons/location.svg'
import { TextArea, TextInput } from '../../components/Form/Inputs'
import { Button } from '../../components/Form/Button'
import Footer from '../../components/Footer/Footer'
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg'
import { ReactComponent as FacebookIconFilled } from '../../assets/icons/facebook-filled.svg'
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg'
import { ReactComponent as TwitterIconFilled } from '../../assets/icons/twitter-filled.svg'
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg'
import { ReactComponent as InstagramIconFilled } from '../../assets/icons/instagram-filled.svg'

const Contact = () => {
  const [inputs, setInputValues] = useState({
    name: '',
    email: '',
    message: '',
  })

  const setValue = (id, value) => {
    setInputValues({
      ...inputs,
      [id]: value,
    })
  }

  return (
    <>
      <header className={classes.header}>
        <Header />
        <img src={topBg} alt="Background" className={classes.headerBg} />
        <div className={classes.headerText}>
          <h1 className={classes.headerTextHeader}>
            Get in touch with us today.
          </h1>
          <h3 className={classes.headerTextSub}>
            For requests and enquiries about GloEpid, kindly reach out to us by
            filling the form below, and we'll be in touch as soon as possible.
          </h3>
        </div>
      </header>
      <main className={classes.main}>
        <div className={classNames(classes.mainGrid, gridClasses.container)}>
          <section>
            <div className={classes.formInputs}>
              <TextInput
                value={inputs.name}
                onChange={(value) => setValue('name', value)}
                label={'Full name (required)'}
              />
              <TextInput
                value={inputs.email}
                onChange={(value) => setValue('email', value)}
                label={'Email address (required)'}
                type={'email'}
              />
              <TextArea
                value={inputs.message}
                onChange={(value) => setValue('message', value)}
                label={'Message  (required)'}
              />
              <Button className={classes.formButton}>Send Message</Button>
            </div>
            {/* <h3 className={classes.formHeader}>Fill out our form</h3> */}
            <p className={classes.formSubtext}>
              Our support team is here to answer your enquiries.
            </p>
          </section>
          <section className={classes.contactDetails}>
            <div>
              <div className={classes.contactDetail}>
                <Location className={classes.contactDetailLocationIcon} />
                <span>7, Omo Ighodalo Street, Ogudu GRA. Ogudu, Lagos.</span>
              </div>
              <div
                className={classNames(
                  classes.contactDetail,
                  classes.contactDetailCenter
                )}
              >
                <Email className={classes.contactDetailEmailIcon} />
                <span>info@gloepid.com</span>
              </div>
            </div>
            <div className={classes.contactDetailSocialMediaLinks}>
              <a href="" className={classes.contactDetailSocialMediaLink}>
                <TwitterIcon
                  className={classes.contactDetailSocialMediaLinkIcon}
                />
                <TwitterIconFilled
                  className={classNames(
                    classes.contactDetailSocialMediaLinkIcon,
                    classes.isFilled
                  )}
                />
              </a>
              <a href="" className={classes.contactDetailSocialMediaLink}>
                <InstagramIcon
                  className={classes.contactDetailSocialMediaLinkIcon}
                />
                <InstagramIconFilled
                  className={classNames(
                    classes.contactDetailSocialMediaLinkIcon,
                    classes.isFilled
                  )}
                />
              </a>
              <a href="" className={classes.contactDetailSocialMediaLink}>
                <FacebookIcon
                  className={classes.contactDetailSocialMediaLinkIcon}
                />
                <FacebookIconFilled
                  className={classNames(
                    classes.contactDetailSocialMediaLinkIcon,
                    classes.isFilled
                  )}
                />
              </a>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Contact
