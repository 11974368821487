import React from 'react'
import classNames from 'classnames'
import classes from './Button.module.css'

export const Button = ({
  children,
  className,
  onClick,
  type = 'button',
  ...props
}) => (
  <button
    className={classNames(classes.button, className)}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
)
