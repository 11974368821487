import React from 'react'
import classNames from 'classnames'
import gridClasses from '../../styles/grid.module.css'
import classes from './Landing.module.css'
import { Header } from '../../components/Header/Header'
import phoneImage from '../../assets/images/landing-page-phone.png'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import { BotEvent } from '../../components/ChatWindow/ChatWindow'
import heroImg1 from '../../assets/images/heroImg1.png'
import heroImg2 from '../../assets/images/heroImg2.png'
import heroImg3 from '../../assets/images/heroImg3.png'
import heroImg4 from '../../assets/images/heroImg4.png'
import bulb from '../../assets/icons/bulbSm.svg'
import { healthData, healthSectionImg } from '../../db/healthData'
import appStore from '../../assets/icons/appStore.svg'
import googlePlay from '../../assets/icons/googlePlay.svg'
import chatlineTop from '../../assets/icons/chatlineTop.svg'
import chatlineBtm from '../../assets/icons/chatlineBtm.svg'
import microsoft from '../../assets/icons/microsoft.svg'
import prunedge from '../../assets/icons/prunedge.svg'
import ventures from '../../assets/icons/ventures.svg'
import tech4dev from '../../assets/icons/tech4dev.svg'
import ncdc from '../../assets/icons/NCDC.svg'

const partners = [
  {
    name: 'Microsoft',
    logo: microsoft,
  },
  {
    name: 'Prunedge',
    logo: prunedge,
  },
  {
    name: 'Ventures Platform',
    logo: ventures,
  },
  {
    name: 'Tech4Dev',
    logo: tech4dev,
    // className: classes.tech4devLogo,
  },
  {
    name: 'NCDC',
    logo: ncdc,
  },
]

const heroImages = [
  {
    img: heroImg1,
  },
  {
    img: heroImg2,
  },
  {
    img: heroImg3,
  },
  {
    img: heroImg4,
  },
]

const passionCards = [
  {
    img: bulb,
    title: 'Humanity',
    subTitle:
      'GloEpid was developed by sympathetic people who care about a world in need. We concentrate our time, energy, and resources on recognizing and celebrating the human experience because we believe that by working together, we can make the world free from epidemics for everyone.',
  },

  {
    img: bulb,
    title: 'Technology',
    subTitle:
      'We have developed a technological solution to anticipate any epidemic and aid decision-making because we believe technology can be used to address some of the world’s most pressing issues.',
  },

  {
    img: bulb,
    title: 'Value',
    subTitle:
      'We are committed to creating value. As a community, we are driven to succeed in flattening the curve and predicting the spread of any epidemic. We are working innovatively to develop strategies that will stop the spread and offer real-time information sharing that can help contain or stop the spread.',
  },
]

export default function LandingPage() {
  const triggerBot = () => {
    const event = new CustomEvent(BotEvent.TriggerBot)
    window.dispatchEvent(event)
  }
  return (
    <React.Fragment>
      <div className={classes.hero}>
        <Header />
        <div
          className={classNames(gridClasses.container, classes.heroContainer)}
        >
          <div className={classes.heroContent}>
            <h1 className={classes.heroTitle}>
              GloEpid is a social enterprise that focuses on leveraging
              technological innovations in healthcare and public health to
              improve health outcomes.
              <h1 className={classes.heroTitle}>
                Unlocking Innovation for a Healthier Africa
              </h1>
            </h1>
            <p className={classes.heroSubtitle}>
              At GloEpid, we are dedicated to transforming healthcare across
              Africa through a multifaceted approach to health technology. Our
              diverse portfolio embodies our commitment to addressing the most
              pressing healthcare challenges on the continent.
            </p>
          </div>
          <div className={classes.heroImagesContainer}>
            {heroImages?.map((image, index) => (
              <img
                key={index}
                src={image.img}
                className={classes.heroImage}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
      <main>
        <section className={classes.pageSection}>
          <div className={gridClasses.container}>
            <h1 className={classes.sectionTitle}>
              Our passion and enthusiasm are driven by these factors:
            </h1>
            <div className={classes.passionCards}>
              {passionCards?.map((card, index) => (
                <div className={classes.passionCard} key={index}>
                  <h2 className={classes.passionCardTitle}>{card.title}</h2>
                  <p className={classes.passionCardDescription}>
                    {card.subTitle}
                  </p>
                  <div className={classes.passionCardImgContainer}>
                    <img
                      src={card.img}
                      alt=""
                      className={classes.passionCardImg}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className={classes.healthSection}>
          <div className={classNames(gridClasses.container)}>
            <p className={classes.healthSectionTitle}>Our area of focus</p>
            <div className={classes.healthSectionContent}>
              <img
                src={healthSectionImg?.img}
                className={classes.healthImg}
                alt="Helath Scetion"
              />
              <div className={classes.healthExplained}>
                {healthData?.map((data, index) => (
                  <div key={index} className={classes.healthCard}>
                    <img
                      src={data?.img}
                      className={classes.healthIcon}
                      alt="Health Icon"
                    />
                    <div>
                      <h className={classes.healthCardTitle}>{data?.title}</h>
                      <p className={classes.healthCardSubtitle}>
                        {data?.subtitle}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section
          className={classNames(
            classes.pageSection,
            classes.pageSectionPrimary
          )}
        >
          <div className={classNames(gridClasses.container, classes.panes)}>
            <div className={classes.imagePane}>
              <img
                src={phoneImage}
                className={classes.phoneImage}
                alt="Screenshot of Gleopid Mobile App"
              />
              <div className={classes.appLinks}>
                <a
                  href="https://www.apple.com/ios/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.appLink}
                  aria-label="Download the iOS app"
                >
                  <img src={appStore} alt="" />
                </a>
                <a
                  href="https://play.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.appLink}
                  aria-label="Download the Android app"
                >
                  <img src={googlePlay} alt="" />
                </a>
              </div>
            </div>
            <div className={classes.appDescription}>
              <div className={classes.sectionTitleGroup}>
                <h5 className={classes.sectionTitleLabel}>Mobile App</h5>
                <h1 className={classes.appSectionTitle}>
                  Download the App, take a Test
                </h1>
                <p className={classes.featureListItemDescription}>
                  In order to battle COVID19 through self-test, we initially
                  introduced the GloEpid solution, which was a major success and
                  was quickly adopted by state governments to assist their
                  medical teams in more effectively addressing the pandemic
                  based on urgency and severity.
                </p>
                <p className={classes.featureListItemDescriptionB}>
                  Some features from our mobile app:
                </p>
              </div>
              <ol className={classes.featureList}>
                <li className={classes.featureListItem}>
                  <h5 className={classes.sectionSpan}>Fast Contact Tracing </h5>
                  <p className={classes.featureListItemDescription}>
                    Using Bluetooth and GPS, other devices with the app
                    installed within a range are identified and stored as an
                    activity log with timestamps and location points. With your
                    permission, this Information is made available to the
                    relevant authorities if it is necessary.
                  </p>
                </li>
                <li className={classes.featureListItem}>
                  <h6 className={classes.featureListItemTitle}>Data Privacy</h6>
                  <p className={classes.featureListItemDescription}>
                    All data is encrypted and stored on your device and cannot
                    be accessed unless you are identified as a close contact or
                    confirmed case of a virus and have given consent.
                  </p>
                </li>
                <li className={classes.featureListItem}>
                  <h6 className={classes.featureListItemTitle}>
                    Self-Assessment Test
                  </h6>
                  <p className={classes.featureListItemDescription}>
                    Take assessment tests in-app, know your Risk factor levels,
                    follow the suggestions and have your report sent directly to
                    the relevant authorities for follow-up activities.
                  </p>
                </li>
              </ol>
              <Link to="/mobile-details" className={classes.learnMoreLink}>
                Learn More
              </Link>
            </div>
          </div>
        </section>

        <section className={classes.pageSection}>
          <div className={gridClasses.container}>
            {/* <ChatWithBotCard
              onButtonClick={triggerBot}
              className={classes.chatCard}
            /> */}
            <div className={classes.chatBoxContainer}>
              <img src={chatlineTop} className={classes.chatlineTop} alt="" />
              <div className={classes.chatBox}>
                <h2 className={classes.chatBoxTitle}>
                  Are you currently overwhelmed about the pandemic and would
                  need to take the self assessment test? Kindly click the button
                  below.
                </h2>
                <button onClick={triggerBot} className={classes.chatBoxButton}>
                  Chat now
                </button>
              </div>
              <img src={chatlineBtm} className={classes.chatlineBtm} alt="" />
            </div>
            <h5 className={classes.partnersSectionTitle}>
              Our amazing partners
            </h5>
            <div className={classes.partnersList}>
              {partners.map((partner, index) => (
                <div
                  key={index}
                  className={classNames(
                    classes.partnersListItem,
                    partner.className
                  )}
                >
                  <img src={partner.logo} alt={partner.name} />
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  )
}
