import React from 'react'
import classes from './footer.module.css'
import { Link } from 'react-router-dom'
import locationIcon from '../../assets/icons/location2.svg'
import x from '../../assets/icons/x.svg'
import ig from '../../assets/icons/ig.svg'
import lnkd from '../../assets/icons/lnkd.svg'

const Footer = () => {
  const footerIcons = [
    {
      name: 'Twitter',
      img: x,
    },
    {
      name: 'Instagram',
      img: ig,
    },
    {
      name: 'LinkedIn',
      img: lnkd,
    },
  ]
  return (
    <footer className={classes.footer}>
      <div className={classes.footerSectA}>
        <img src={locationIcon} alt="logo" className={classes.locationIcon} />
        <p>7, Omo Ighodalo Street, Ogudu GRA, Lagos</p>
      </div>
      <div>
        <Link to={'/contact'} className={classes.footerLink}>
          Contact Us
        </Link>
      </div>
      <div className={classes.footerIcons}>
        {footerIcons?.map((icon, i) => (
          <img
            key={i}
            src={icon?.img}
            alt={icon?.name}
            className={classes.footerIcon}
          />
        ))}
      </div>
    </footer>
  )
}

export default Footer
