import adeola from '../../assets/images/adeola-onasanya.png'
import joel from '../../assets/images/joel-ogunsola.png'
import lanre from '../../assets/images/lanre-akomolafe.png'

export const Team = [
  { name: 'Adeola Onasanya', title: 'Executive Director', img: adeola },
  { name: 'Joel Ogunsola', title: 'Head of Patnerships', img: joel },
  { name: 'Lanre Akomolafe', title: 'Head of Products', img: lanre },
]

export default Team
