import React, { useState } from 'react'
import classNames from 'classnames'
import classes from './Header.module.css'
import logo from '../../assets/images/logo.svg'
import menuOpen from '../../assets/icons/menuOpen.svg'
import menuClose from '../../assets/icons/close.svg'
import gridClasses from '../../styles/grid.module.css'
import { Link, NavLink } from 'react-router-dom'

const headerLinks = [
  {
    title: 'Products',
    link: '/products',
  },
  {
    title: 'Services',
    link: '/services',
  },
  {
    title: 'About us',
    link: '/about',
  },
  // {
  //   title: 'Covid visualiser',
  //   link: 'https://covidvisualizer.com',
  //   isExternal: true,
  // },
  {
    title: 'Contact us',
    link: '/contact',
  },
]

export const Header = ({ className = '' }) => {
  const [openHeader, setOpenHeader] = useState(false)

  return (
    <div className={classNames(classes.header, className)}>
      <div
        className={classNames(gridClasses.container, classes.headerContainer)}
      >
        <Link to="/">
          <img className={classes.logo} alt="GloEpid" src={logo} />
        </Link>
        <nav className={classes.headerNav}>
          {headerLinks.map((link) =>
            !link.isExternal ? (
              <NavLink
                key={link.title}
                to={link.link}
                className={classes.headerNavItem}
                activeClassName={classes.headerNavItemActive}
              >
                {link.title}
              </NavLink>
            ) : (
              <a
                key={link.title}
                href={link.link}
                className={classes.headerNavItem}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
            )
          )}
        </nav>
        <div className={classes.hamburger}>
          <img
            className={classes.menuIcon}
            alt="menuIcon"
            src={`${!openHeader ? menuOpen : menuClose}`}
            onClick={() => setOpenHeader(!openHeader)}
          />
        </div>
      </div>
      {openHeader === true && (
        <div className={classes.headerMobileWrap}>
          {headerLinks?.map((link) => (
            <div key={link.title} className={classes.headerNavItemMobile}>
              <NavLink
                key={link.title}
                to={link.link}
                className={classes.headerNavItem}
                activeClassName={classes.headerNavItemActive}
              >
                {link.title}
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
