import healthSystem from '../assets/icons/healtSystem.svg'
import healthTech from '../assets/icons/healthTech.svg'
import healthCare from '../assets/icons/healthCare.svg'
import healthAI from '../assets/icons/healthAI.svg'
import healthEdu from '../assets/icons/healthEdu.svg'
import healthAssess from '../assets/icons/healthAssess.svg'
import healthImg from '../assets/images/healthImg.png'

export const healthSectionImg = { img: healthImg }

export const healthData = [
  {
    img: healthSystem,
    title: 'Health Systems Strengthening',
    subtitle:
      'We support the health system using health technology and design principles to co-create products, services and systems with stakeholders',
  },
  {
    img: healthTech,
    title: 'Health Technology Development',
    subtitle:
      'We develop software and applications to support healthcare and public health needs.',
  },
  {
    img: healthCare,
    title: 'Healthcare Analytics',
    subtitle:
      'We leverage data analytics and artificial intelligence to extract valuable insights from healthcare data, enhancing patient outcomes, cost-efficiency, and operational effectiveness.',
  },
  {
    img: healthAI,
    title: 'AI-Enhanced Health Surveillance',
    subtitle:
      'We apply AI and machine learning for diagnostics, predictive analytics, disease surveillance and personalised medicine. Our Gloepid Bot was used by the Nigeria Centre for Disease Control for COVID-19 surveillance',
  },
  {
    img: healthEdu,
    title: 'Health Education and Training',
    subtitle:
      'We develop e-learning platforms and simulations for medical professionals, students and the general populace',
  },
  {
    img: healthAssess,
    title: 'Health Technology Assessment',
    subtitle: 'We assess health technology fit for the African setting',
  },
]
