import React from 'react'
import gridClasses from '../../styles/grid.module.css'
import classes from './AboutUs.module.css'
import { Header } from '../../components/Header/Header'
import classNames from 'classnames'
import Footer from '../../components/Footer/Footer'
import Team from './TeamMock'
import microsoft from '../../assets/icons/microsoft.svg'
import prunedge from '../../assets/icons/prunedge.svg'
import ventures from '../../assets/icons/ventures.svg'
import tech4dev from '../../assets/icons/tech4dev.svg'
import ncdc from '../../assets/icons/NCDC.svg'
import map from '../../assets/icons/map.svg'
import focus from '../../assets/icons/focus.svg'
import logoTrans from '../../assets/icons/logoTrans.svg'

const partners = [
  {
    name: 'Microsoft',
    logo: microsoft,
  },
  {
    name: 'Prunedge',
    logo: prunedge,
  },
  {
    name: 'Ventures Platform',
    logo: ventures,
  },
  {
    name: 'Tech4Dev',
    logo: tech4dev,
  },
  {
    name: 'NCDC',
    logo: ncdc,
  },
]

const AboutUs = () => {
  return (
    <>
      <section className={classes.showcase}>
        <Header />

        <section className={classNames(classes.aboutUs, gridClasses.container)}>
          <div className={classes.aboutHero}>
            <div className={classes.aboutTextWrapper}>
              <h2 className={classes.heroTitle}>About Us</h2>
              <p className={classes.heroSubtitle}>
                At GloEpid, we are more than just a health technology company;
                we are a driving force behind transformative healthcare
                solutions for Africa. Our commitment to innovation, data-driven
                decision-making, and education is rooted in our vision of a
                healthier and more equitable future for all Africans. Join us on
                this journey towards improved healthcare and wellness.
              </p>
              <img src={logoTrans} alt="logo" className={classes.logoTrans} />
            </div>
            <div className={classes.mapWrapper}>
              <img src={map} alt="African Map" className={classes.map} />
              <img src={focus} alt="Area of focus" className={classes.focus} />
            </div>
          </div>
          <div className={classes.partnersList}>
            {partners.map((partner, index) => (
              <div
                key={index}
                className={classNames(
                  classes.partnersListItem,
                  partner.className
                )}
              >
                <img src={partner.logo} alt={partner.name} />
              </div>
            ))}
          </div>
        </section>
      </section>
      <section className={classes.teamSection}>
        <div className={classNames(gridClasses.container)}>
          <div className={classes.teamSectionTextWrapper}>
            <p className={classes.teamSectionText}>
              We are a team of changemakers passionate about solving the world’s
              greatest challenges through technology.
            </p>
            <p className={classes.teamSectionSubText}>
              We are vibrant, diverse and agile; these qualities and many others
              are the sources of our strength; they shape our views and guide
              our actions.
            </p>
          </div>
          <div className={classes.teamCards}>
            {Team.map((data, index) => (
              <div key={index} className={classes.teamCard}>
                <img
                  src={data.img}
                  alt={data.name}
                  className={classes.teamImg}
                />
                <p className={classes.teamName}>{data?.name}</p>
                <p className={classes.teamTitle}>{data?.title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default AboutUs
