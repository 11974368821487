import React from 'react'
import classNames from 'classnames'
import classes from './Inputs.module.css'

export const TextInput = ({
  className,
  type = 'text',
  label,
  value,
  onChange,
  style,
  ...props
}) => {
  const handleInput = (event) => {
    const { value } = event.target
    onChange(value, event)
  }
  const hasContent = !!value
  return (
    <div className={classNames(classes.inputGroup, className)} style={style}>
      <label className={classes.label} htmlFor={`input-${label}`}>
        {label}
      </label>
      <input
        id={`input-${label}`}
        className={classNames(
          classes.input,
          hasContent && classes.inputHasContent
        )}
        type={type}
        value={value}
        onChange={handleInput}
        {...props}
      />
    </div>
  )
}

export const TextArea = ({
  className,
  label,
  value,
  onChange,
  style,
  ...props
}) => {
  const handleInput = (event) => {
    const { value } = event.target
    onChange(value, event)
  }
  const hasContent = !!value
  return (
    <div className={classNames(classes.inputGroup, className)} style={style}>
      <label className={classes.label} htmlFor={`input-${label}`}>
        {label}
      </label>
      <textarea
        id={`input-${label}`}
        className={classNames(
          classes.input,
          hasContent && classes.inputHasContent,
          classes.inputTextarea
        )}
        onChange={handleInput}
        {...props}
      >
        {value}
      </textarea>
    </div>
  )
}
