import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import classes from './ChatWindow.module.css'
import { ChipInput } from './Chips'

export const InputBox = ({
  className,
  disabled = false,
  onSendMessage,
  ...props
}) => {
  const [value, setValue] = useState('')
  const isEmpty = !value.trim()
  const handleChange = (event) => {
    const { value } = event.target
    setValue(value)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (disabled || isEmpty) return
    if (typeof onSendMessage === 'function') {
      onSendMessage({
        multiChoice: false,
        message: value,
      })
      setValue('')
    }
  }
  return (
    <form className={classes.chatInputWrapper} onSubmit={handleSubmit}>
      <input
        className={classes.chatInput}
        value={value}
        onChange={handleChange}
        placeholder="Write a reply..."
        aria-label="Write a reply"
        disabled={disabled}
      />
    </form>
  )
}
export const ChoiceInput = ({
  options,
  onSendMessage,
  className,
  multipleChoice = false,
  ...props
}) => {
  const [selectedIndexes, setSelectedIndexes] = useState([])
  const [sentResponse, setSentResponse] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    // the user must select at least one item
    // and the user can't send twice
    if (selectedIndexes.length === 0 || sentResponse) return
    if (typeof onSendMessage === 'function') {
      setSentResponse(true)
      onSendMessage({
        choice: true,
        selectedIndexes,
        options,
        singleChoice: !multipleChoice,
      })
    }
  }
  useEffect(() => {
    // This is essentially resetting state when we get new props
    // If the previous question also had options, React doesn't
    // unmount us, so we have to reset state oursevles
    return () => {
      setSelectedIndexes([])
      setSentResponse(false)
    }
  }, [options])
  // The backend ensures "None" is always the last item in the array
  const noneIndex = options.length - 1
  const toggleOption = (optionIndex) => {
    // The user shouldn't be able to change their decision after clicking send
    if (sentResponse) return
    if (!multipleChoice) {
      // Somehow, the user is attempting to select something else
      // while a selection is already active
      // This shouldn't be possible without the send button
      // So, we ignore it.
      if (selectedIndexes.length > 0) return
      setSelectedIndexes([optionIndex])
      // immediately send the message for single choice questions
      if (typeof onSendMessage === 'function') {
        onSendMessage({
          choice: true,
          selectedIndexes: [optionIndex],
          options,
          singleChoice: !multipleChoice,
        })
      }
    }
    // deselect everything else when the user selects none
    else if (optionIndex === noneIndex) setSelectedIndexes([noneIndex])
    else if (selectedIndexes.includes(optionIndex)) {
      setSelectedIndexes(
        selectedIndexes.filter((selectedIndex) => selectedIndex !== optionIndex)
      )
    } else {
      // deselect None when anything else is selected
      setSelectedIndexes([
        ...selectedIndexes.filter(
          (selectedIndex) => selectedIndex !== noneIndex
        ),
        optionIndex,
      ])
    }
  }
  const isSelected = (option) => selectedIndexes.includes(option)
  return (
    <form
      className={classNames(classes.chipsInput, className)}
      {...props}
      onSubmit={handleSubmit}
    >
      <div className={classes.chipList}>
        {options.map((option, index) => (
          <ChipInput
            label={option}
            selected={isSelected(index)}
            onClick={() => toggleOption(index)}
            key={option}
          />
        ))}
      </div>
      {multipleChoice && (
        <button
          type="submit"
          className={classNames(
            classes.sendButton,
            classes.multiChoiceSendButton
          )}
          disabled={sentResponse}
        >
          Send
        </button>
      )}
    </form>
  )
}
