import moment from 'moment'

const TEST_TAKEN_FLAG = 'gleopid-test-flag'

export const HOURS_BETWEEN_TESTS = 6

let canAccessLocalStorage = true

try {
  canAccessLocalStorage = window.localStorage !== undefined
} catch (_) {
  canAccessLocalStorage = false
}

if (!canAccessLocalStorage && process.env.REACT_APP_STAGE !== 'production')
  console.warn(
    'localStorage is not accessible. Rate limits have been disabled.'
  )

export const markTestTaken = () => {
  canAccessLocalStorage &&
    localStorage.setItem(
      TEST_TAKEN_FLAG,
      JSON.stringify({
        testTaken: true,
        date: new Date(),
      })
    )
}

const hasTakenTest = () =>
  canAccessLocalStorage ? !!localStorage.getItem(TEST_TAKEN_FLAG) : false

export const getTimeTestWasTaken = () =>
  hasTakenTest() && JSON.parse(localStorage.getItem(TEST_TAKEN_FLAG)).date

export const canTakeTest = () => {
  if (hasTakenTest()) {
    const item = JSON.parse(localStorage.getItem(TEST_TAKEN_FLAG))
    const date = moment(item.date)
    const rightNow = moment(new Date())
    return (
      rightNow > date &&
      rightNow.diff(date, 'hours', true) > HOURS_BETWEEN_TESTS
    )
  } else {
    return true
  }
}
