import React from 'react'
import classNames from 'classnames'
import classes from './GetTheAppCard.module.css'
import { ReactComponent as AppStoreLight } from '../../../assets/images/app-store-light.svg'
import { ReactComponent as PlayStoreLight } from '../../../assets/images/play-store-light.svg'

export const GetTheAppCard = ({ className, ...props }) => (
  <div className={classNames(classes.getTheAppCard)} {...props}>
    <h3 className={classes.title}>Get the app</h3>
    <p className={classes.subtitle}>
      Help speed up contact tracing efforts, take assessment tests and stay
      informed about COVID-19
    </p>
    <div className={classes.appLinks}>
      <a
        href="https://play.google.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.appLink}
        aria-label="Download the Android app"
      >
        <PlayStoreLight />
      </a>
      <a
        href="https://www.apple.com/ios/app-store/"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.appLink}
        aria-label="Download the iOS app"
      >
        <AppStoreLight />
      </a>
    </div>
  </div>
)
