import React from 'react'
import classes from './products.module.css'
import { Header } from '../../components/Header/Header'
import gridClasses from '../../styles/grid.module.css'
import phoneImg from '../../assets/images/phone.png'
import appStore from '../../assets/icons/appstoreBg.svg'
import googlePlay from '../../assets/icons/googleBg.svg'
import Footer from '../../components/Footer/Footer'

function Products() {
  return (
    <div>
      <div className={classes.productHero}>
        <Header />
        <div className={gridClasses.container}>
          <div className={classes.productHeroWrapper}>
            <div className={classes.appInfo}>
              <h2 className={classes.appTitle}>GloEpid App</h2>
              <p className={classes.appDescription}>
                Infectious diseases pose grave threats to public health, with
                re-emerging and novel diseases causing severe impacts.
                Outbreaks, like Ebola and COVID-19, devastate communities with
                limited resources. Early detection, enabled by prediction models
                like GloEpid, is crucial in containment and prevention.
                GloEpid's app enhances global health by offering early epidemic
                outbreak detection for safety.
              </p>
              <div className={classes.appLinks}>
                <a
                  href="https://play.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.appLink}
                  aria-label="Download the Android app"
                >
                  <img src={googlePlay} alt="" />
                </a>
                <a
                  href="https://www.apple.com/ios/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.appLink}
                  aria-label="Download the iOS app"
                >
                  <img src={appStore} alt="" />
                </a>
              </div>
            </div>
            <div>
              <img src={phoneImg} alt="" className={classes.phoneImg} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Products
